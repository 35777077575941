<template>
  <div class="no-scroll">
    <div class="container no-scroll text-not-selected" :style="{ padding: `${containerPadding}px`, '--count-per-line': countPerLine,
    '--image-width': `${imageWidth}px`, '--image-box-margin': `${imageBoxMargin}px`, ...waterStyle }">
      <!-- 瀑布流图片 -->
      <div v-for="(item, key) of list" :key="key" class="image-box" @click="handleGoStone(item.id)"
           :style="{ width: `${item.image.width}px`, 'grid-row': `var(--item-span-${key})` }">
        <img alt="image" :src='item.image.url + "?x-oss-process=image/resize,w_300,m_lfit"'
             :style="{ width: `${imageWidth}px`, height: `${item.image.height}px` }" />
        <div class="title" :style="{ width: `calc(${item.image.width}px - 20px)`,
        'line-height': `${imageTitleHeight}px`, height: `${imageTitleHeight}px`}">{{item.title}}</div>
      </div>
    </div>

    <!-- 加载提示 -->
    <loading :is-loading="isLoading" :is-all-loaded="isAllLoaded"></loading>
  </div>
</template>

<script>
import _ from 'lodash'
import page from '@/mixins/page'
import Loading from '@/components/loading/loading'
import { isNumber } from '@/utils/number'

export default {
  name: 'StoneList',

  data () {
    return {
      // 瀑布流的定义变量
      waterStyle: {},
      // 外部两侧扣除的间距
      externalDeductWidth: 0,
      // 容器内间距，px
      containerPadding: 20,
      // 图片标题栏高度，px
      imageTitleHeight: 30,
      // 图片外间距，px
      imageBoxMargin: 20,
      // 图片固定宽度，px
      imageWidth: 300,
      // 每行有几张图片
      countPerLine: 5
    }
  },

  computed: {
    // 获取列表的参数
    args () {
      return {
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        },
        stone: {
          filter: {}
        }
      }
    },
    // 搜索的参数
    searchArgs () {
      return {
        keyword: this.keyword,
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  props: {
    keyword: {
      type: String,
      default: ''
    },
    color: {
      type: String
    },
    material: {
      type: String
    },
    texture: {
      type: String
    }
  },

  watch: {
    keyword: function () {
      this.handleResetPage()
      this.firstGetData()
    }
  },

  components: { Loading },

  mixins: [page],

  mounted () {
    // 监听滚动到底的事件
    window.addEventListener('scroll', this.onReachBottom(this.getStoneList))
    // 监听浏览器窗口大小改变
    window.onresize = _.debounce(() => {
      this.handleResetPage()
      this.firstGetData()
    }, 300)
    this.firstGetData()
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.onReachBottom(this.getStoneList))
    window.onresize = null
  },

  methods: {
    // 首次请求数据
    firstGetData () {
      // 检测剩余宽度，并计算出可容纳的宽度
      const remainingWidth = document.documentElement.clientWidth - this.containerPadding * 2
      this.countPerLine = Math.floor(remainingWidth / (this.imageWidth + this.imageBoxMargin))
      this.isLoading = true
      this.getStoneList(true)
    },

    // 获取数据
    getStoneList (isLazy = false) {
      const tags = []
      if (this.color && isNumber(this.color)) tags.push(this.color)
      if (this.material && isNumber(this.material)) tags.push(this.material)
      if (this.texture && isNumber(this.texture)) tags.push(this.texture)
      if (tags.length > 0) this.args.stone.filter.tags = tags
      this.api.getStoneList(this.args).then(res => {
        if (isLazy) {
          this.list = res.data.getStoneList.data
        } else {
          this.list = this.list.concat(res.data.getStoneList.data)
        }
        this.total = res.data.getStoneList.total
        this.isLoading = false
        this.isAllLoaded = this.list.length >= this.total
        this.arrangeImages()
      }).catch(() => {
        this.isLoading = false
      })
    },

    // 重新布局图片
    arrangeImages () {
      const waterStyle = {}
      const list = this.list
      for (const i in list) {
        const item = list[i]
        const ratio = item.image.width / this.imageWidth
        list[i].image.width = this.imageWidth
        const height = item.image.height / ratio
        list[i].image.height = height
        // grid-auto-row
        const span = Math.ceil((height + this.imageTitleHeight + this.imageBoxMargin) / 10)
        waterStyle[`--item-span-${i}`] = `auto / span ${span}`
      }
      this.list = list
      this.waterStyle = waterStyle
    },

    // 跳转石材详情页
    handleGoStone (id) {
      this.$router.push({
        path: `/detail/${id}`
      })
    }
  }
}
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(var(--count-per-line), var(--image-width));
  grid-auto-rows: 10px;
  grid-auto-flow: row dense;
  column-gap: var(--image-box-margin);
}

.image-box {
  box-shadow: 0 0 15px 5px #dcdcdc;
  margin-bottom: var(--image-box-margin);
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}
.image-box img {
  border-radius: 15px 15px 0 0;
}
.image-box > .title {
  position: absolute;
  bottom: 0;
  font-size: 16px;
  font-weight: 600;
  padding: 0 10px;
  border-radius: 0 0 15px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  margin-left: 10px;
}
</style>
